define("discourse/plugins/discourse-remote-embed/discourse-markdown/embed-protocol", ["exports", "xss"], function (_exports, _xss) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  // add link to array if href is an embed
  function addEmbed(embeds, token) {
    if (token.attrs) {
      for (let i = 0; i < token.attrs.length; i++) {
        const value = token.attrs[i][1];
        if (value?.startsWith("embed://")) {
          embeds.push({
            token,
            srcIndex: i,
            origSrc: value
          });
          break;
        }
      }
    }
  }
  function processToken(embeds, token) {
    if (token.tag === "a") {
      addEmbed(embeds, token);
    }
    if (token.children) {
      for (let j = 0; j < token.children.length; j++) {
        const childToken = token.children[j];
        processToken(embeds, childToken);
      }
    }
  }
  function rule(state) {
    let embeds = [];
    for (let i = 0; i < state.tokens.length; i++) {
      let blockToken = state.tokens[i];
      processToken(embeds, blockToken);
    }
    console.log('THE EMBEDS', embeds);
    if (embeds.length > 0) {
      let srcList = embeds.map(u => u.origSrc);

      // In client-side cooking we will fetch the actual embed content
      // as its contents may change and this code will also run when cooking a post.
      // We just add the resolved embed url as a data attribute
      embeds.forEach(_ref => {
        let {
          token,
          srcIndex,
          origSrc
        } = _ref;
        if (token.tag === "a") {
          token.attrs.push(["data-orig-embed-url", origSrc.replace(/^embed:\/\//, 'https://')]);
        }
      });
    }
  }
  function setup(helper) {
    const opts = helper.getOptions();
    helper.allowList(["a[data-orig-embed-url]"]);
    helper.registerPlugin(md => {
      // just treat literal embed:// protocol urls like https://
      // and turn them in to links
      md.linkify.add('embed:', 'https:');
      md.core.ruler.push("embed-protocol", rule);
    });
  }
});