define("discourse/plugins/discourse-remote-embed/discourse/initializers/gitlab_embed", ["exports", "discourse/lib/plugin-api", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/highlight-syntax"], function (_exports, _pluginApi, _ajax, _ajaxError, _highlightSyntax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializePlugin(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    if (siteSettings.checklist_enabled) {
      api.decorateCookedElement(gitlabEmbed, {
        onlyStream: true
      });
    }
  }
  async function gitlabEmbed(elem, decoratorHelper) {
    const siteSettings = decoratorHelper.container.lookup("site-settings:main");
    console.log('site settings', siteSettings);
    console.log('embed key', siteSettings.gitlab_embed_api_token);
    const session = decoratorHelper.container.lookup("service:session");
    console.log('adding embeds to', elem);
    const embedUrlElems = elem.querySelectorAll('a[data-orig-embed-url]');
    if (!embedUrlElems) {
      console.log("no embed urls found");
      return;
    }
    for await (const embedUrlElem of embedUrlElems) {
      const embedUrl = embedUrlElem.dataset.origEmbedUrl;
      console.log("embeddable urls found", embedUrl);
      try {
        const res = await (0, _ajax.ajax)("/remote_embed", {
          data: {
            embed_url: embedUrl
          }
        });
        const embedContent = res.content;
        const embedElem = document.createElement("pre");
        const codeElem = document.createElement("code");
        codeElem.classList.add("lang-auto");
        codeElem.classList.add("hljs");
        codeElem.textContent = embedContent;
        embedElem.appendChild(codeElem);
        await (0, _highlightSyntax.default)(embedElem, siteSettings, session);
        embedUrlElem.closest('p').before(embedElem);
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      }
    }
  }
  var _default = _exports.default = {
    name: "gitlab_embed",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.6.0", api => initializePlugin(api));
    }
  };
});